.media {
  flex: 1;
  display: flex;
  justify-content: center;
  .media__cover-img {
    object-fit: cover;
    width: 220px;
    height: 250px;
    border-radius: 8px;
  }
  .media__margin-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  .media__card-wrapper {
    padding-bottom: 30px;
    .row--margin-0 {
      margin: 0;
    }
    .media__card-row {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      .media__btn-cover {
        cursor: pointer;
        padding: 0;
        margin: 0;
        min-width: 150px;
        height: 180px;
        border-radius: 8px;
        background: transparent;
        .media__cover-overlay {
          object-fit: cover;
          width: 150px;
          height: 180px;
          border-radius: 8px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          position: absolute;
          z-index: 99;
        }
        .media-play-icon {
          width: 40px;
          height: 40px;
          position: relative;
          top: 40%;
          left: 40%;
        }
        .media__cover {
          position: relative;
          z-index: 0;
          object-fit: cover;
          width: 150px;
          height: 180px;
          border-radius: 8px;
        }
      }

      .media__text-column {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        .ellipsis-text {
          white-space: nowrap;
          width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .media__btn-wrapper {
      display: flex;
      justify-content: left;
      margin-top: 0.75rem;
    }
  }

  .media__choose-image {
    .media__edit-form-col {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .media__image-view {
      width: 220px;
      height: 250px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 99;
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
    .media__label-input {
      border-color: transparent !important;
      background-color: transparent !important;
      width: 220px;
      height: 250px;
      display: flex;
      justify-content: center;
      .create-author_profile {
        width: 220px;
        height: 250px;
        border-radius: 8px;
        background-color: lightgray;
        border: 5px solid snow;
      }
      .media__choose-image-icon {
        width: 60px;
        height: 60px;
        margin-top: 95px;
      }
      .media__preview-image {
        width: 220px;
        height: 250px;
        border-radius: 8px;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }
    }
  }
  .media__edit-file-btn {
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }
  .media__edit-footer-btn {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }
}
.public-criteria-data {
  background-color: #f2f4f7;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.media__category {
  .media__category--toggle-view {
    cursor: pointer;
    background-color: transparent;
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #f2f4f7;
  }
  .d-flex__space-between {
    display: flex;
    justify-content: space-between;
  }
  .category-modal-content {
    padding: 0;
    .category__list-group-item {
      z-index: 0;
      border: none;
      padding: 5px 0;
      .margin-top-5 {
        margin-top: 5px;
      }
      .icon-wrapper {
        margin: 0;
        padding: 8px;
        background: none;
        border-color: none;
        cursor: pointer;
      }
      .radio-field-wrapper {
        padding: 2px 10px;
        display: flex;
      }
      .icon-right {
        margin-top: 10px;
        padding-left: 10px;
      }
    }
    .d-flex__flex-end {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .modal-btn {
      padding: 10px;
      bottom: 0;
      z-index: 99;
      box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.15);
    }
  }
}
.media__reference {
  .media_ref-card {
    background: rgba(0, 0, 0, 0.1);
    padding: 0;
    height: 320px;
    .plus-container {
      height: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
    .wrapper-icon {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .icon-size {
      width: 42px;
      height: 42px;
    }
  }
  .media_ref-card-img {
    padding: 0;
    box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.15);
    height: 330px;
    .img-wrapper {
      height: 180px;
      width: 95%;
      margin: 5px;
      cursor: pointer;
      .media__cover-overlay {
        // object-fit: cover;
        width: 95%;
        height: 180px;
        border-radius: 8px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        position: absolute;
        z-index: 99;
      }
      .media-play-icon {
        width: 40px;
        height: 40px;
        position: relative;
        top: 40%;
        left: 40%;
      }
      .media__cover {
        position: relative;
        z-index: 0;
        object-fit: cover;
        width: 100%;
        height: 180px;
        border-radius: 8px;
      }
    }
    .media-cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
.media__view-file-url {
  margin-bottom: 0;
  border: 1px solid #f2f4f7;
  padding: 8px;
  white-space: nowrap;
  min-width: 353px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  &:hover {
    color: #70bbfd;
    cursor: pointer;
  }
}
