.loading-view {
  display: flex;
  flex: 1;
  margin: 0;
  // margin: 0 15px;
  .loading-view__container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
    .padding-bottom-30 {
      padding-bottom: 30px;
      .sm-width-height {
        width: 165px;
        height: 150px;
        border-radius: 8px;
        background: #F8F9F9
      };
      .lg-width-height {
        width: 300px;
        height: 15px;
        border-radius: 2px;
        background: #f8f9f9;
        margin-bottom: 15px;
      };
      .width-250 {
        width: 250px;
        height: 15px;
        border-radius: 2px;
        background: #F8F9F9;
        margin-bottom: 15px;
      };
      .width-300 {
        width: 300px;
        height: 15px;
        border-radius: 2px;
        background: #F8F9F9;
        margin-bottom: 30px
      }
    }
  } 
}