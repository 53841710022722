// .author{
  .author-list {
    flex: 1;
    .auhtor-list__pagination {
      flex: 1;
      background-color: white;
      margin: 0 0 20px 0;
      padding: 0 15px 15px 15px;
      display: flex;
      justify-content: flex-end
    }
    .author-list__avatar {
        background-size: cover;
        width: 160px;
        height: 160px;
        border-radius: 80px;
        align-self: center;
        object-fit: cover;
        border: 5px solid snow;
        margin-bottom: 0.75rem;
        padding: 2px;
    };
    .author-list__footer {
        display: flex;
        justify-content: center;
        margin-top: 0.75rem;
    };
    .author-list__filter {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
    }
  };

  .create-author {
    flex: 1;
    justify-content: center;
    display: flex;
    .create-author_profile-view {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .create-author-btn {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    .create-author__label-input {
      border-color: transparent !important;
      background-color: transparent !important;;
      width: 160px;
      height: 160px;
      .create-author_profile {
        width: 160px;
        height: 160px;
        border-radius: 80px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        border: 5px solid snow;
        z-index: 99;
        position: absolute;
      }
      .create-author__icon { 
        width: 60px;
        height: 60px;
        margin-top: 50px;
      }
      .create-author__preview-image {
        width: 160px;
        height: 160px;
        border-radius: 80px;
        object-fit: cover;
        border: 5px solid snow;
        // padding: 2px;
        position: relative;
        z-index: 1
      }
    }
  }